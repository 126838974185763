/* You can add global styles to this file, and also import other style files */

.filter-tbl {
  width: 100%;
}

.filter-tbl td.label {
  width: 14%;
  color: #000;
}

.filter-tbl td.text {
  width  : 25%;
  color  : #000;
  padding: 10px 20px 10px 0px;
}
.filter-tbl td.text select, .filter-tbl td.text input {
  font-size: 13px;
}

#users {
  font-family    : "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width          : 100%;
}

#users td,
#users th {
  border        : 1px solid #ddd;
  padding       : 8px;
  text-align    : left;
  vertical-align: middle;
}

#users tr:nth-child(even) {
  background-color: #f2f2f2;
}

#users tr:hover {
  background-color: #ddd;
}

#users th {
  padding-top     : 12px;
  padding-bottom  : 12px;
  text-align      : left;
  background-color: #DF373B;
  /* #00A7E6;*/
  /* #FF8084;*/
  color           : white;
}

.users {
  font-family    : "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width          : 100%;
}

.users td,
.users th {
  border        : 1px solid #ddd;
  padding       : 8px;
  text-align    : left;
  vertical-align: middle !important;
}

.users tr:nth-child(even) {
  background-color: #f2f2f2;
}

.users tr:hover {
  background-color: #ddd;
}

.users th {
  padding-top     : 12px;
  padding-bottom  : 12px;
  text-align      : left;
  background-color: #DF373B;
  vertical-align: middle !important;
  /* #00A7E6;*/
  /* #FF8084;*/
  color           : white;
}

.delete {
  background-color: orangered;
  color           : #fff;
  display         : inline-block;
  padding         : 0.45em 0.8em;
  font-size       : 85%;
  cursor          : pointer;
  font-weight     : 700;
  line-height     : 1;
  text-align      : center;
  white-space     : nowrap;
  vertical-align  : baseline;
  border-radius   : 0.25rem;
  transition      : color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.delete:hover {
  background-color: rgb(255, 68, 0);
  color           : #fff;
  display         : inline-block;
  padding         : 0.45em 0.8em;
  font-size       : 85%;
  cursor          : pointer;
  font-weight     : 700;
  line-height     : 1;
  text-align      : center;
  white-space     : nowrap;
  vertical-align  : baseline;
  border-radius   : 0.25rem;
  transition      : color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.review {
  background-color: rgb(183, 0, 255);
  color           : #fff;
  display         : inline-block;
  cursor          : pointer;
  padding         : 0.45em 0.8em;
  margin          : 5px 0 0 0;
  font-size       : 85%;
  font-weight     : 700;
  line-height     : 1;
  text-align      : center;
  white-space     : nowrap;
  vertical-align  : baseline;
  border-radius   : 0.25rem;
  transition      : color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.review:hover {
  background-color: #fff;
  color           : rgb(183, 0, 255);
  display         : inline-block;
  padding         : 0.45em 0.8em;
  margin          : 5px 0 0 0;
  font-size       : 85%;
  font-weight     : 700;
  line-height     : 1;
  text-align      : center;
  white-space     : nowrap;
  vertical-align  : baseline;
  border-radius   : 0.25rem;
  transition      : color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.orange {
  background-color: rgb(255, 153, 0);
  color           : #fff;
  display         : inline-block;
  padding         : 0.45em 0.8em;
  font-size       : 85%;
  font-weight     : 700;
  line-height     : 1;
  text-align      : center;
  white-space     : nowrap;
  vertical-align  : baseline;
  border-radius   : 0.25rem;
  transition      : color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.left-tbl {
  border-radius: 10px 0 0 0;
  border       : 0 !important;
}

.right-tbl {
  border-radius: 0 10px 0 0;
  border       : 0 !important;
}

.center-align {
  text-align: center !important;
}

.left-align {
  text-align: left !important;
}

.space-span {
  padding: 0 10px;
}

.color-green {
  color: #81ba00;
}

.color-red {
  color: red;
}